// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDsKNJfuXqr4mKuBKZkldZqnLhceXhlhhM',
  authDomain: 'examweb-staging.firebaseapp.com',
  databaseURL: 'https://examweb-staging.firebaseio.com',
  projectId: 'examweb-staging',
  storageBucket: 'examweb-staging.appspot.com',
  messagingSenderId: '41864284505',
  appId: '1:41864284505:web:dbdb6aadedc19beaba2448'
};

export const firebaseLocation = 'asia-northeast1';

export const commonIdConfig = {
  authorizationEndpoint: 'https://id-stg.obunsha.co.jp/v1/authorize',
  redirectUri: 'https://kakomon-stg.obunsha.co.jp/c/sign-in/callback',
  clientId: 'brfCzKm9kDbAWsWgx7ZE8aQaEz8eKKEtMCGKQV5pnbEj',
  profileUrl: 'https://id-stg.obunsha.co.jp/settings/profile/',
  faqUrl: 'https://id-stg.obunsha.co.jp/help/',
  ssc: {
    cookieApiUri: 'https://api.ssc-stg.obunsha.co.jp/v1/cookies'
  }
};

export const passNaviConfig = {
  faqUrl: 'https://passnavi-stg.obunsha.co.jp/faq/'
};

export const gaConfig = ['G-SYDKE7G2F1'];

export const redirectDomainConfig = {
  from: 'examweb-staging.web.app',
  to: 'kakomon-stg.obunsha.co.jp'
};

export const manualPdfFiles = {
  userManual: 'https://storage.googleapis.com/examweb-staging.appspot.com/help-manual/nyushidigi_standard_manual.pdf',
  adminManual: 'https://storage.googleapis.com/examweb-staging.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};
export const downloadFiles = {
  universitiesListPdf: 'https://storage.googleapis.com/examweb-staging.appspot.com/help-manual/nyushidigi_daigakulist_obunsha.pdf',
  membersImportTemplateCsv: 'https://storage.googleapis.com/examweb-staging.appspot.com/help-manual/template.csv',
  membersImportManualPdf: 'https://storage.googleapis.com/examweb-staging.appspot.com/help-manual/nyushidigi_dantaikanri_manual.pdf'
};

export const microCmsConfig = {
  adminUrl: 'https://stg-kakomon.microcms.io/apis/informations'
};

export const stripeConfig = {
  userPaymentsUrl: 'https://dashboard.stripe.com/test/search?query=<user-id>%20is%3Apayment'
};

export const cloudfrontHost = 'https://d26n0q0r6pk9pi.cloudfront.net/';
export const lambdaHost = 'https://mm2lxvrtjmx363vf65xqx3frji0ewpzv.lambda-url.ap-northeast-1.on.aws/';

export const sentryDsn = 'https://2d4b57a06a734d8b818fbd0a2f1eaece@o1071032.ingest.sentry.io/6067688';
export const release = '20241113-release';
